const isLoading = ref(false)
export function useLoading() {
  const showWrap  = ref(true)

  const text = ref('Loading...')
  const loading = {
    start: (show = true) => {
      isLoading.value = true
      showWrap.value = show
    },
    stop: () => isLoading.value = false
  }

  const startLoading = () => isLoading.value = true
  const stopLoading = () => isLoading.value = false

  const withLoading = async (fn, show = true) => {
    try {
      loading.start(show)
      await fn()
    } finally {
      loading.stop()
    }
  }

  return {
    isLoading: readonly(isLoading),
    showWrap: readonly(showWrap),
    text,
    loading,
    startLoading,
    stopLoading,
    withLoading,
  }
}

export const useGlobalLoading = useLoading()
